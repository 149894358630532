/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import {
  Button,
  TextField as MUITextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import './ProductsUpdate.scss';
import SwalUI from '../../../../components/ui/swal-ui/swal-ui';
import {
  getBusinessType,
  updateRentalData,
} from '../../../../services/rent.service';
import { useSelector } from 'react-redux';

function ProductsUpdate(props) {
  const {
    selectedRow,
    tenantData,
    setUpdate,
    setRefreshData,
    handleCloseUpdate,
  } = props;
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const userInfoHandler = useSelector((state) => state.auth.profile);
  // const [searchInput, setSearchInput] = useState('');
  const [age, setAge] = useState('');
  const [selectedDate1, setSelectedDate1] = useState({});
  const [editData, setEditData] = useState(selectedRow);

  const [openshop, setOpenShop] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectBusiness, setSelectBusiness] = useState([]);
  // const [reFund, setReFund] = useState(0);
  const [dataShop, setDataShop] = useState({});
  const formInputRef = useRef(null);
  console.log(editData);
  // console.log(userInfoHandler.username);

  useEffect(() => {
    getBusinessType()
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setSelectBusiness(res.data.business);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const businessOptions = selectBusiness.map((zone, index) => (
    <MenuItem key={index} value={zone.id}>
      {/* {zone.types_name} */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            borderRadius: '3px',
            height: '15px',
            width: '15px',
            backgroundColor: zone.box_color,
            marginRight: '6px',
          }}
        />
        <div>{zone.types_name}</div>
      </div>
    </MenuItem>
  ));

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);

    setEditData((prevState) => {
      return { ...prevState, BusinessId: selectedValue };
    });
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const rowsFiltered = tenantData.filter((row) => {
    if (
      (age === '' || row.zone_name === age) && // Filter by Zone
      row.tenant_name.includes(searchQuery)
    ) {
      return true;
    } else {
      return false;
    }
  });

  const handleResultClick = (selectedShop) => {
    const shop = tenantData.find((row) => row.shopId === selectedShop);
    setSearchQuery(shop.tenant_name);
    setEditData((prevState) => {
      return {
        ...prevState,
        tenantId: shop.tenantId,
        shopId: shop.shopId,
        tenantName: shop.tenant_name,
        tenant_phone: shop.phone_th,
        tenant_nickname: shop.phone_kh,
        tenant_line: shop.tenant_line,
        tenant_mail: shop.tenant_mail,
        tenant_telegram: shop.tenant_telegram,
        tenant_whatsapp: shop.tenant_whatsapp,
        // deposit: selectedRow.Deposit,
        // rent : selectedRow.Rent,
        // tentDeposit : selectedRow.TentDeposit,
        // remain : selectedRow.Overdue,
        // note : selectedRow.note
      };
    });
    setOpenShop(false);
  };

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  // const searchSupplier = (e) => {
  //   setSearchInput(e.target.value);
  // };

  const submitEditData = () => {
    const RentalDate = editData.RentalDate
      ? moment(editData.RentalDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD[T]HH:mm:ss'
        )
      : '';

    const Endrent = editData.Endrent
      ? moment(editData.Endrent, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss')
      : '';

    const formData = new FormData();
    formData.append('id', editData.id);
    formData.append('tenantId', editData.tenantId);
    formData.append('shopId', editData.shopId);
    formData.append('tenantName', editData.tenantName);
    formData.append('phoneTh', editData.tenant_nickname);
    formData.append('phoneKh', editData.tenant_phone);
    formData.append('rentalDate', RentalDate);
    formData.append('endrent', Endrent);
    formData.append('tenant_line', editData.tenant_line);
    formData.append('tenant_mail', editData.tenant_mail);
    formData.append('tenant_telegram', editData.tenant_telegram);
    formData.append('tenant_whatsapp', editData.tenant_whatsapp);
    formData.append('rentalStatus', editData.RentalStatus);
    formData.append('created_by', userInfoHandler.username);
    //ข้อมูลร้าน
    formData.append('shopName', editData.NameShop);
    formData.append('businessId', editData.BusinessId);
    formData.append('shopNote', editData.Shopnote);
    formData.append(
      'shopImage',
      selectedImage ? selectedImage : editData.ShopImage
    );
    //ข้อมูลคืนเงิน
    formData.append('Refund', editData.refund); // จำนวนเงินที่คืน
    formData.append('note', editData.note); //หมายเหตุ
    //billId
    formData.append('billId', editData.billId);

    /* Display the key/value pairs*/
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    // return false;

    updateRentalData(formData).then((res) => {
      console.log(res);
      if (res.status === 200) {
        setRefreshData((prev) => prev + 1);
        SwalUI({
          status: res.data.status,
          // description: res.data.description
        });
        setUpdate(false);
      }
    });
  };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileUploader = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
          setEditData((prevState) => {
            return { ...prevState, ShopImage: file };
          });
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const StoreInfo = tenantData.find((row) => row.shopId === selectedRow.shopId);
  // setSelectedDate1(StoreInfo);
  // console.log(StoreInfo);

  return (
    <section id="products-page">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          gap: '2rem',
          marginBottom: '1.5rem',
        }}
      >
        <div style={{ width: '100%', fontSize: '1.75rem', fontWeight: 600 }}>
          อัปเดต ข้อมูลการเช่า
        </div>
      </div>
      {/* Cards ข้อมูลผู้เช่า */}
      <div className="box-store-data">
        <div className="store-information">
          <div style={{ width: '100%', fontSize: '1.5rem', fontWeight: 400 }}>
            ข้อมูลผู้เช่าร้าน
          </div>
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: '16px',
              background: '#6C5DD3',
              color: 'white',
              height: '39px',
              paddingLeft: '1.5rem',
              paddingRight: '1.5rem',
              width: '150px',
            }}
            component="a"
            className="button-h"
            onClick={handleOpenShop}
          >
            ค้นหาผู้เช่า
          </Button>
          <Modal open={openshop} onClose={handleCloseShop}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'white',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                p: 4,
              }}
            >
              <TextField
                label="ค้นหาร้าน"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchInputChange}
                autoFocus
                sx={{ mb: 2 }}
                InputProps={{
                  style: {
                    color: 'black',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: '1px solid #ddd',
                          padding: '8px',
                          textAlign: 'center',
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6C5DD3',
                        }}
                      >
                        เลือกผู้เช่า
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsFiltered.map((result) => (
                      <tr
                        key={result.shopId}
                        onClick={() => handleResultClick(result.shopId)}
                        style={{
                          cursor: 'pointer',
                          '&:hover': { backgroundColor: '#f0f0f0' },
                        }}
                      >
                        <td
                          style={{
                            borderBottom: '1px solid #ddd',
                            padding: '8px',
                          }}
                        >
                          {result.tenant_name + ' (' + result.Business + ')'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Modal>
        </div>

        {/* กรอกข้อมูล */}
        <div className="from-box-data">
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="ชื่อผู้เช่า"
                value={editData.tenantName}
                // helperText="Some important text"
                variant="standard"
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, tenantName: event.target.value };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="เบอร์โทรศัพท์ (TH)"
                value={editData.tenant_nickname}
                // helperText="Some important text"
                variant="standard"
                onChange={(event) =>
                  setEditData((prevState) => {
                    return {
                      ...prevState,
                      tenant_nickname: event.target.value,
                    };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="เบอร์โทรศัพท์ (KH)"
                value={editData.tenant_phone}
                // helperText="Some important text"
                variant="standard"
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, tenant_phone: event.target.value };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="ไลน์"
                value={editData.tenant_line}
                // helperText="Some important text"
                variant="standard"
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, tenant_line: event.target.value };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="อีเมล"
                value={editData.tenant_mail}
                variant="standard"
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, tenant_mail: event.target.value };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="Telegram"
                value={editData.tenant_telegram}
                variant="standard"
                onChange={(event) =>
                  setEditData((prevState) => {
                    return {
                      ...prevState,
                      tenant_telegram: event.target.value,
                    };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="WhatApp"
                value={editData.tenant_whatsapp}
                variant="standard"
                onChange={(event) =>
                  setEditData((prevState) => {
                    return {
                      ...prevState,
                      tenant_whatsapp: event.target.value,
                    };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="วันที่เริ่มเช่า"
                  inputFormat="DD/MM/YYYY"
                  value={moment(editData.RentalDate, 'DD/MM/YYYY')}
                  onChange={(date) => {
                    setEditData((prevState) => {
                      return {
                        ...prevState,
                        RentalDate: date.format('DD/MM/YYYY'),
                      };
                    });
                  }}
                  renderInput={(params) => (
                    <MUITextField
                      id="standard-helperText"
                      variant="standard"
                      {...params}
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </Box>
        </div>
        {/*  */}
      </div>
      {/* Cards ข้อมูลร้าน */}
      <div className="box-store-data">
        <div className="store-information">
          <div style={{ width: '100%', fontSize: '1.5rem', fontWeight: 400 }}>
            ข้อมูลร้าน
          </div>
        </div>
        <div className="from-box-data">
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              justifyItems: 'center',
            }}
            noValidate
            autoComplete="off"
          >
            <figure
              className="figure-add"
              style={{ cursor: 'pointer', marginBottom: '0.5rem' }}
            >
              {selectedImage && (
                <img
                  className="content-images-add"
                  src={selectedImage}
                  alt="Selected"
                />
              )}

              {/* แสดงรูปภาพที่เลือก */}
              <div className="input-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={previewImageHandler}
                  ref={formInputRef}
                  style={{ display: 'none' }}
                />
              </div>
              {selectedImage ? (
                ''
              ) : (
                <img
                  className="content-images-add"
                  src={uploadPath + StoreInfo.ShopImage}
                  alt="Selected"
                  style={{ width: '100%', height: '120px' }}
                />
              )}
            </figure>

            <div className="mt-3">
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: '16px',
                  background: '#6C5DD3',
                  color: 'white',
                  height: '39px',
                  width: '108px',
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                }}
                className="button-h"
                onClick={openFileUploader}
              >
                อัปโหลด
              </Button>
            </div>
          </Box>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-basic"
              label="ชื่อร้าน"
              value={editData.NameShop}
              variant="standard"
              onChange={(event) =>
                setEditData((prevState) => {
                  return { ...prevState, NameShop: event.target.value };
                })
              }
            />
          </Box>

          <FormControl
            fullWidth
            variant="standard"
            sx={{ m: 1, minWidth: 120 }}
          >
            <InputLabel id="demo-simple-select-standard-label">
              ประเภทการทำธุรกิจ
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={editData.BusinessId}
              onChange={handleChange}
              label="ประเภทการทำธุรกิจ"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              {businessOptions}
            </Select>
          </FormControl>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            {/* <textarea
              aria-label="empty textarea"
              placeholder="รายระเอียดประเภทธุรกิจ"
              value={tenantData.shop ? tenantData.shop.note : ''}
              onChange={(event) =>
                setTenantData((prevState) => ({
                  ...prevState,
                  shop: { ...prevState.shop, note: event.target.value },
                }))
              }
              style={{
                border: '1px solid #808080',
                borderRadius: '5px',
                padding: '8px',
                width: '100%',
                height: '80px',
                resize: 'vertical',
              }}
            /> */}

            <TextField
              id="standard-multiline-static"
              label="Multiline"
              multiline
              rows={3}
              defaultValue={editData.Shopnote}
              variant="standard"
              onChange={(event) =>
                setEditData((prevState) => {
                  return { ...prevState, Shopnote: event.target.value };
                })
              }
            />
          </Box>
        </div>
      </div>
      {/* Cards คืนเงิน */}
      <div className="box-store-data">
        <div className="store-information">
          <div style={{ width: '100%', fontSize: '1.ถrem', fontWeight: 400 }}>
            คืนเงิน
          </div>
        </div>
        <div className="from-box-data">
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                label="จำนวนที่คืน"
                value={editData.refund}
                // helperText="Some important text"
                variant="standard"
                InputProps={{
                  inputProps: {
                    pattern: '[0-9]', // Regular expression allowing only numbers
                  },
                }}
                onInput={(event) => {
                  const { value } = event.target;
                  const newValue = value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with empty string
                  event.target.value = newValue; // Set the new value
                }}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, refund: event.target.value };
                  })
                }
              />
            </div>
          </Box>
        </div>
        <div className="flex-textarea">
          <TextField
            id="outlined-multiline-static"
            label="หมายเหตุ"
            value={editData.note !== null ? editData.note : ''}
            onChange={(event) =>
              setEditData((prevState) => {
                return { ...prevState, note: event.target.value };
              })
            }
            multiline
            rows={4}
          />
          {/* <textarea
            aria-label="empty textarea"
            placeholder="หมายเหตุ"
            value={editData.note !== null ? editData.note : ''}
            // onChange={(event) => setNote(event.target.value)}
            style={{
              border: '1px solid #808080',
              borderRadius: '5px',
              padding: '8px',
              width: '100%',
              height: '80px',
              resize: 'vertical',
            }}
          /> */}
        </div>
      </div>
      <div className="block-status">
        <h1 className="block-h1">สถานะข้อมูลผู้เช่าร้าน</h1>
        <div className="flex-status">
          <FormControl fullWidth size="small" style={{ width: '165px' }}>
            <InputLabel id="demo-select-small-label">สถานะผู้เช่า</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={editData.RentalStatus}
              label="สถานะผู้เช่า"
              onChange={(event) =>
                setEditData((prevState) => {
                  return { ...prevState, RentalStatus: event.target.value };
                })
              }
            >
              <MenuItem value={1} disabled={editData.RentalStatus === '0'}>
                ยังเช่าอยู่
              </MenuItem>
              {/* <MenuItem value={2}>เปลี่ยนสัญญา</MenuItem> */}
              <MenuItem value={0}>ย้ายออกแล้ว</MenuItem>
            </Select>
          </FormControl>
          <h1
            className="flex-status-h1"
            style={{
              color:
                editData.RentalStatus == 1 || editData.RentalStatus == 2
                  ? '#00D715'
                  : '#FF2C2C',
            }}
          >
            {editData.RentalStatus == 1 || editData.RentalStatus == 2
              ? 'ยังเช่าอยู่'
              : 'ย้ายออกแล้ว'}
          </h1>
          {/* <p className="flex-status-p">{editData.Endrent}</p> */}
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันที่ย้ายออก"
                inputFormat="DD/MM/YYYY"
                value={moment(editData.Endrent, 'DD/MM/YYYY')}
                onChange={(date) => {
                  setEditData((prevState) => {
                    return {
                      ...prevState,
                      Endrent: date.format('DD/MM/YYYY'),
                    };
                  });
                }}
                renderInput={(params) => (
                  <MUITextField
                    id="standard-helperText"
                    variant="standard"
                    {...params}
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>

      <div className="status-button">
        <Button
          variant="contained"
          size="small"
          className="button-h"
          style={{
            fontSize: '16px',
            background: '#707070',
            color: 'white',
            height: '39px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }}
          onClick={() => {
            handleCloseUpdate();
          }}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#6C5DD3',
            color: 'white',
            height: '39px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }}
          component="a"
          className="button-h"
          onClick={submitEditData}
        >
          บันทึก
        </Button>
      </div>
    </section>
  );
}

export default ProductsUpdate;
