import { Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { svUpdataRentDetail } from '../../../services/payrent.service';
import SwalUI from '../../../components/ui/swal-ui/swal-ui';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

function HistoryRentt(props) {
  const { editData, onClose, setRefreshData } = props;
  const webPath = useSelector((state) => state.app.uploadPath);
  const [editDataRentt, setEditDataRentt] = useState(editData);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isError, setIsError] = useState({
    startDate: false,
    endDate: false,
    overdueDate: false,
    payRent: false,
    payFines: false,
    not: false,
    totalDiscount:  false,
    totalPay: false,
    totalDays: false,
    imgslip: false,
  });
  const formInputRef = useRef(null);
  const [totalFine, settTotalFine] = useState(0);

  useEffect(() => {
    setEditDataRentt({
      startDate: moment(editData.RentalDateStart,"L"),
      endDate:  moment(editData.RentalDateEnd,"L"),
      overdueDate: editData.overdue_date,
      payRent: editData.ex_rent,
      payFines: (!isNaN(editData.fines) && !isNaN(editData.overdue_date) && editData.overdue_date !== 0)
  ? Number(editData.fines) / Number(editData.overdue_date)
  : 0,
      totalDiscount: editData.discount,
      not: editData.note,
      totalPay: editData.total_amount,
      totalDays: editData.total_days,
      imgslip: editData.slip,
    });
    setSelectedImage(webPath + editData.slip);
  }, []);

  useEffect(() => {
    const totalfine = ((editDataRentt.overdueDate * editDataRentt.payFines) + editDataRentt.payRent) - editDataRentt.totalDiscount;
    settTotalFine(totalfine);
  }, [editDataRentt]);
  // console.log(moment(editDataRentt.endDate).format('L'));

  const handleButtonClick = () => {
    formInputRef.current.click();
  };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdate = () => {
    setIsError({
      ...isError,
      payRent: editDataRentt.payRent === 0 || isNaN(editDataRentt.payRent),
      totalDays:
        editDataRentt.totalDays === 0 || isNaN(editDataRentt.totalDays),
    });
    if (isError.payRent || isError.totalDays) {
      return false;
    }

    const formData = new FormData();
    formData.append('Id', editData.id);
    formData.append(
      'slip',
      formInputRef.current.files[0] === undefined
        ? editData.image
        : formInputRef.current.files[0]
    );
    formData.append('start_date', editDataRentt.startDate.toString());
    formData.append('end_date', editDataRentt.endDate.toString());
    formData.append('total_days', editDataRentt.totalDays);
    formData.append('overdue_date', editDataRentt.overdueDate);
    formData.append('rent', editDataRentt.payRent);
    formData.append('fines', editDataRentt.payFines);
    formData.append('discount', editDataRentt.totalDiscount);
    formData.append('total_amount', totalFine);
    formData.append('note', editDataRentt.not);

    /* Display the key/value pairs*/
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    // return false;
    svUpdataRentDetail(formData)
      .then((res) => {
        console.log(res);
        setRefreshData((prev) => prev + 1);
        if (res.status) {
          SwalUI({
            status: res.description,
          });
          onClose(false);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <section id="historyrentt-modail">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          gap: '2rem',
          marginBottom: '1.5rem',
        }}
      >
        <div style={{ width: '100%', fontSize: '1.75rem', fontWeight: 500 }}>
          อัปเดตประวัติเช่า/ชำระเงิน
        </div>
        <div style={{ width: '25%' }}>
          <TextField
            id="filled-multiline-flexible"
            label="รวมยอดที่จะต้องเก็บ"
            multiline
            //   maxRows={4}
            variant="filled"
            // value={editDataRentt.totalPay}
            value={totalFine}
            InputProps={{
              disableUnderline: true,
              style: {
                background: '#f3f4f6',
                border: '1px solid #808080',
                borderRadius: '5px',
              },
            }}
            disabled
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div style={{ width: '50%', padding: '0.25rem' }}>
            <DatePicker
              label="ตั้งแต่วันที่"
              inputFormat="DD/MM/YYYY"
              // value={dayjs(editDataRentt.startDate, 'DD/MM/YYYY')}
              value={editDataRentt.startDate}
              onAccept={(value) =>
                setEditDataRentt((prevState) => {
                  return {
                    ...prevState,
                    startDate: moment(value),
                  };
                })
              }
              onChange={() => true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: '100%',
                  }}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  required
                />
              )}
            />
          </div>

          <div style={{ width: '50%', padding: '0.25rem' }}>
            <DatePicker
              label="ถึงวันที่"
              inputFormat="DD/MM/YYYY"
              // value={dayjs(editDataRentt.endDate, 'DD/MM/YYYY')}
              value={editDataRentt.endDate}
              onAccept={(value) =>
                setEditDataRentt((prevState) => {
                  return {
                    ...prevState,
                    endDate: moment(value),
                  };
                })
              }
              onChange={() => true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: '100%',
                  }}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  required
                />
              )}
            />
          </div>
        </LocalizationProvider>
        <div style={{ width: '25%', padding: '0.25rem' }}>
          <TextField
            id="standard-basic"
            label="ค่าเช่า"
            multiline
            maxRows={4}
            variant="filled"
            error={isError.payRent}
            value={editDataRentt.payRent || 0}
            onChange={(event) => {
              setEditDataRentt((prevState) => {
                return {
                  ...prevState,
                  payRent: parseFloat(event.target.value),
                };
              });
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                background: 'white',
                border: '1px solid #808080',
                borderRadius: '5px',
              },
            }}
          />
        </div>
        <div style={{ width: '12.5%', padding: '0.25rem' }}>
          <TextField
            id="standard-basic"
            label="รวมเป็นจำนวนวัน"
            multiline
            maxRows={4}
            variant="filled"
            error={isError.totalDays}
            defaultValue={0}
            value={editDataRentt.totalDays || 0}
            onChange={(event) => {
              setEditDataRentt((prevState) => {
                return {
                  ...prevState,
                  totalDays: parseFloat(event.target.value),
                };
              });
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                background: 'white',
                border: '1px solid #808080', // เปลี่ยนสีขอบตามเงื่อนไข
                borderRadius: '5px',
              },
            }}
          />
        </div>
        <div style={{ width: '12.5%', padding: '0.25rem' }}>
          <TextField
            id="standard-basic"
            label="จำนวนวันที่เกินมา"
            multiline
            maxRows={4}
            variant="filled"
            value={editDataRentt.overdueDate || 0}
            onChange={(event) => {
              setEditDataRentt((prevState) => {
                return {
                  ...prevState,
                  overdueDate: parseFloat(event.target.value) || 0,
                };
              });
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                background: 'white',
                border: '1px solid #808080',
                borderRadius: '5px',
              },
            }}
          />
        </div>
        <div style={{ width: '25%', padding: '0.25rem' }}>
          <TextField
            id="standard-basic"
            label="ค่าปรับ"
            multiline
            maxRows={4}
            variant="filled"
            value={editDataRentt.payFines || 0}
            onChange={(event) => {
              setEditDataRentt((prevState) => {
                return {
                  ...prevState,
                  payFines: parseFloat(event.target.value) || 0,
                };
              });
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                background: 'white',
                border: '1px solid #808080',
                borderRadius: '5px',
              },
            }}
          />
        </div>
        <div style={{ width: '25%', padding: '0.25rem' }}>
          <TextField
            id="standard-basic"
            label="ส่วนลด"
            multiline
            maxRows={4}
            variant="filled"
            value={editDataRentt.totalDiscount || 0}
            onChange={(event) => {
              setEditDataRentt((prevState) => {
                return {
                  ...prevState,
                  totalDiscount: parseFloat(event.target.value) || 0,
                };
              });
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                background: 'white',
                border: '1px solid #808080',
                borderRadius: '5px',
              },
            }}
          />
        </div>
        <div style={{ width: '50%', padding: '0.25rem' }}>
          <textarea
            aria-label="empty textarea"
            placeholder="หมายเหตุ"
            value={editDataRentt.not !== null ? editDataRentt.not : ''}
            onChange={(event) => {
              setEditDataRentt((prevState) => {
                return {
                  ...prevState,
                  not: event.target.value,
                };
              });
            }}
            // minRows={4}
            style={{
              border: '1px solid #808080',
              borderRadius: '5px',
              padding: '8px',
              width: '100%',
              resize: 'vertical',
              height: '100px',
            }}
          />
        </div>

        <div className="flex-box" style={{ width: '50%' }}>
          {/* <div className="content-lefts"> */}
          <div className={`content-lefts ${isError.imgslip ? 'error' : ''}`}>
            <div className="figure-container" onClick={handleButtonClick}>
              <figure
                style={{ cursor: 'pointer', width: '50%', margin: '0 auto' }}
              >
                {selectedImage && (
                  <img
                    className="content-images w-full h-full"
                    src={selectedImage}
                    alt="Selected"
                  />
                )}

                {/* แสดงรูปภาพที่เลือก */}
                <div className="input-file">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={previewImageHandler}
                    ref={formInputRef}
                    style={{ display: 'none' }}
                    error={isError.imgslip}
                  />
                </div>
                {selectedImage ? (
                  ''
                ) : (
                  <div className="figure-text">
                    <InsertPhotoOutlinedIcon
                      fontSize="large"
                      style={{ color: 'grey' }}
                    />
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#707070',
                        textAlign: 'center',
                      }}
                    >
                      รูปถ่าย หรือ รูปสลิปหลักฐานการจ่าย
                    </p>
                  </div>
                )}
              </figure>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '0.5rem',
            }}
          >
            <Button
              onClick={handleButtonClick}
              variant="contained"
              size="small"
              style={{
                fontSize: '16px',
                background: '#6C5DD3',
                color: 'white',
                height: '35px',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
              className="button-h"
            >
              เลือกรูปภาพ
            </Button>
          </div>
        </div>
      </div>
      {/*  */}
      <div
        className="modal-footer"
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '1rem',
          gap: '0.5rem',
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={handleUpdate}
          style={{
            fontSize: '16px',
            background: '#6C5DD3',
            color: 'white',
            height: '39px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            width: '150px',
          }}
          component="a"
          className="button-h"
        >
          บันทึกข้อมูล
        </Button>

        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#707070',
            color: 'white',
            height: '39px',
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            width: '150px',
          }}
          component="a"
          onClick={onClose}
          // href="/expiration"
          className="button-d"
        >
          ยกเลิก
        </Button>
      </div>
    </section>
  );
}

export default HistoryRentt;
