import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';

function formattedData({ row, ImgPath }) {
  const RentDateValue = row.Endrent;
  const ExpDateValue = row.ExpEndDate;
  const startRemainValue = row.ReceiptOverdue;
  const ExpRemainValue = row.ExpOverdue;
  let Rent = row.Rent;
  let Own = row.ExpOverdue === null ? row.ReceiptOverdue : row.ExpOverdue;
  let Fine = row.fines;

  return {
    RentPayDate: ExpDateValue || RentDateValue,
    BlockNumber: row.BlockNumber,
    ImgShop: row.ImgShop ? ImgPath + row.ImgShop : '/images/no-image.png',
    Name: row.tenantName,
    Business: row.Business,
    TentDeposit: row.TentDeposit
      ? new Intl.NumberFormat('th-TH', { maximumFractionDigits: 0 }).format(
          row.TentDeposit
        )
      : '',
    Rent: row.Rent
      ? new Intl.NumberFormat('th-TH', { maximumFractionDigits: 0 }).format(
          row.Rent
        )
      : '',
    NotYetPaid: (ExpRemainValue === null ? startRemainValue : ExpRemainValue)
      ? new Intl.NumberFormat('th-TH', { maximumFractionDigits: 0 }).format(
          ExpRemainValue === null ? startRemainValue : ExpRemainValue
        )
      : '',
    fines:
      row.fines == null
        ? '----฿'
        : new Intl.NumberFormat('th-TH', { maximumFractionDigits: 0 }).format(
            row.fines
          ),
    total: new Intl.NumberFormat('th-TH', { maximumFractionDigits: 0 }).format(
      Rent + Own + (Fine || 0)
    ),
    NumberRentalDays: row.NumberRentalDays || '--/--/--',
    Status: row.status_pay === 1 ? 'จ่ายแล้ว' : 'ยังไม่จ่าย',
    slip: row.slip ? ImgPath + row.slip : '/images/no-image.png',
    id: row.id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  { id: 'RentPayDate', numeric: false, disablePadding: true, label: 'วันที่เก็บค่าเช่า' },
  { id: 'BlockNumber', numeric: false, disablePadding: true, label: 'เลขที่บล็อก' },
  { id: 'ImgShop', numeric: false, disablePadding: true, label: 'ภาพหน้าร้าน' },
  { id: 'Name', numeric: false, disablePadding: true, label: 'ชื่อผู้เช่า' },
  { id: 'Business', numeric: false, disablePadding: true, label: 'ประเภทธุรกิจ' },
  { id: 'TentDeposit', numeric: false, disablePadding: true, label: 'ค่ามัดจำเต็นท์' },
  { id: 'Rent', numeric: false, disablePadding: true, label: 'ค่าเช่า' },
  { id: 'NotYetPaid', numeric: false, disablePadding: true, label: 'คงค้าง' },
  { id: 'fines', numeric: false, disablePadding: true, label: 'ค่าปรับ' },
  { id: 'total', numeric: false, disablePadding: true, label: 'ยอดรวมที่ต้องเก็บ' },
  { id: 'NumberRentalDays', numeric: false, disablePadding: true, label: 'จำนวนวันรอบบิล' },
  { id: 'Status', numeric: false, disablePadding: true, label: 'สถานะการจ่าย' },
  { id: 'slip', numeric: false, disablePadding: true, label: 'หลักฐานการจ่าย' },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="custom-data-grid" sx={{ backgroundColor: '#EEEEEE' }}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rowData,
  ImgPath,
  paginateData,
  page,
  setPage,
  setIdShop,
  setTotalSelectednameRent,
  setTotalSelectedNameShop,
  setTotalSelectedBlockNumber,
  setTotalSelectedRent,
  srtTotalSelectedOverdue,
  setSelectedRows,
  setTotalSelectedDeposit,
  handleOpen,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('BlockNumber');
  const [selected, setSelected] = useState([]);
  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    setVisibleRows([...rowData].sort(getComparator(order, orderBy)));
  }, [order, orderBy, page, rowData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowData.map((n) => n.id);
      setSelected(newSelected);
      selectedRows(newSelected);
      return;
    }
    setSelected([]);
  };

  const getUniqueNames = (items, key) => {
    return [...new Set(items.map((item) => item[key]))];
  };

  const selectedRows = (newSelected) => {
    const selectedRowsData = newSelected.map((id) =>
      rowData.find((row) => row.id === id)
    );
console.log(selectedRowsData);

    // const totalSelectedDeposit = selectedRowsData.reduce((accumulator, row) => {
    //   return accumulator + row.Deposit;
    // }, 0);

    const blockAndRent = selectedRowsData.map((row) => ({
      blockNumber: row.BlockNumber,
      rent: row.Rent,
      overdue: row.ReceiptOverdue,
      name: row.tenantName,
      nameShop: row.NameShop,
      endrent: row.Endrent,
      id: row.id,
      billId: row.BillId,
    }));

    // const blockNumbers = blockAndRent.map((item) => item.blockNumber);
    // const rents = blockAndRent.map((item) => item.rent);
    // const overdues = blockAndRent.map((item) => item.overdue);
    const uniqueNames = getUniqueNames(blockAndRent, 'name');
    // const uniqueNameShops = getUniqueNames(blockAndRent, 'nameShop');

    // setIdShop(blockAndRent);
    setTotalSelectednameRent(uniqueNames);
    // setTotalSelectedNameShop(uniqueNameShops);
    // setTotalSelectedBlockNumber(blockNumbers);
    // setTotalSelectedRent(rents);
    // srtTotalSelectedOverdue(overdues);
    setSelectedRows(blockAndRent);
    // setTotalSelectedDeposit(totalSelectedDeposit);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // console.log(newSelected);
    
    setSelected(newSelected);
    selectedRows(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowData.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const formattedRow = formattedData({ row, ImgPath });
                const isItemSelected = selected.includes(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {formattedRow.RentPayDate}
                    </TableCell>
                    <TableCell align="right">{formattedRow.BlockNumber}</TableCell>
                    <TableCell align="right">
                      <img
                        src={formattedRow.ImgShop}
                        alt="Shop"
                        style={{ borderRadius: '0.375rem', width: '59px', height: '59px' }}
                        onClick={() => handleOpen(formattedRow.ImgShop)}
                      />
                    </TableCell>
                    <TableCell align="right">{formattedRow.Name}</TableCell>
                    <TableCell align="right">{formattedRow.Business}</TableCell>
                    <TableCell align="right">{formattedRow.TentDeposit}</TableCell>
                    <TableCell align="right">{formattedRow.Rent}</TableCell>
                    <TableCell align="right">{formattedRow.NotYetPaid}</TableCell>
                    <TableCell align="right">{formattedRow.fines}</TableCell>
                    <TableCell align="right">{formattedRow.total}</TableCell>
                    <TableCell align="right">{formattedRow.NumberRentalDays}</TableCell>
                    <TableCell align="right" sx={{ color: formattedRow.Status === 'ยังไม่จ่าย' ? '#FF2C2C' : '#00D715' }}>
                      {formattedRow.Status}
                    </TableCell>
                    <TableCell align="right">
                      <img
                        src={formattedRow.slip}
                        alt=""
                        style={{ maxHeight: '90px', maxWidth: '100%' }}
                        onClick={() => handleOpen(formattedRow.slip)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={paginateData.total}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Paper>
    </Box>
  );
}