import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { height } from '@mui/system';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ButtonUI from '../../../../components/ui/button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function TablePayrent({
  detailPayment,
  handleOpenEditDate,
  deleteHandle,
  exportedProductDetails,
  refreshData,
  setRefreshData,
}) {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: 'RentalDate',
      headerName: 'วันที่',
      width: 120,
      sortable: true,
      renderCell: (params) => {
        const DateEndvalue = params.row.RentalDateEnd;
        const DateStartvalue = params.row.RentalDateStart;

        if (!DateEndvalue || !DateEndvalue.trim()) {
          return <p>--/--/--</p>;
        }
        return (
          <>
          <div>
          <Typography>{DateStartvalue}</Typography>
          <Typography>{DateEndvalue}</Typography>
             </div>
         </>
        );
      },
    },
    {
      field: 'image',
      headerName: 'ภาพหน้าร้าน',
      width: 100,
      headerClassName: 'table-columns',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <img
          src={uploadPath + params.value}
          alt="Shop"
          style={{
            width: 100,
            borderRadius: '0.375rem',
            width: '59px',
            height: '59px',
          }}
          onClick={() => handleOpen(params.value)}
        />
      ),
    },
    {
      field: 'BlockNumber',
      headerName: 'เลขที่บล็อก',
      sortable: false,
      width: 100,
    },
    {
      field: 'name',
      headerName: 'ชื่อผู้เช่า',
      width: 140,
      sortable: false,
    },
    {
      field: 'Business',
      headerName: 'ประเภทธุรกิจ',
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                borderRadius: '3px',
                height: '14px',
                width: '14px',
                backgroundColor: params.row.color,
                marginRight: '6px',
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: 'tentfee',
      headerName: 'ค่ามัดจำเต็นท์',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return new Intl.NumberFormat('th-TH', {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },

    {
      field: 'deposit',
      headerName: 'ค่ามัดจำ',
      width: 140,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return new Intl.NumberFormat('th-TH', {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat('th-TH', {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: 'ex_rent',
      headerName: 'ค่าเช่า',
      width: 100,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return new Intl.NumberFormat('th-TH', {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      // headerAlign: "center",
      // align: "center",
    },
    // {
    //   field: "ElectricityBill",
    //   headerName: "ค่าไฟ",
    //   width: 100,
    //   sortable: false,
    //   valueFormatter: (params) => {
    //     if (params.value == null) {
    //       return "";
    //     }
    //     return new Intl.NumberFormat("th-TH", {
    //       maximumFractionDigits: 0,
    //     }).format(params.value);
    //   },
    // },
    {
      field: 'fines',
      headerName: 'ค่าปรับ',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat('th-TH', {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: 'discount',
      headerName: 'ส่วนลด',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat('th-TH', {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: 'note',
      headerName: "หมายเหตุ",
      width: 140,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      // renderHeader: () => (
      //   <div>
      //     <Typography
      //       style={{ fontSize: '14px', fontWeight: 500, lineHeight: '27px' }}
      //     >
      //       วันที่ต้องจ่ายค่า
      //     </Typography>
      //     <Typography
      //       style={{ fontSize: '14px', fontWeight: 500, lineHeight: '14px' }}
      //     >
      //       เช่าในแต่ละเดือน
      //     </Typography>
      //   </div>
      // ),
      // renderCell: (params) => {
      //   const PayRentvalue = params.value;

      //   if (!PayRentvalue || !PayRentvalue.trim()) {
      //     return <p>วันที่ 01 ของทุกเดือน</p>;
      //   }
      //   return PayRentvalue;
      // },
    },
    {
      field: 'total_amount',
      width: 120,
      sortable: false,
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: '27px' }}
          >
            ยอดรวม
          </Typography>
          <Typography
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: '14px' }}
          >
            ที่ต้องเก็บ
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat('th-TH', {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: 'slip',
      headerName: 'หลักฐานการจ่าย',
      width: 120,
      headerClassName: 'table-columns',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={() => handleOpen(uploadPath + params.row.slip)}
        >
          <img
            src={
              params.value
                ? `/images/mingcute_paper-line.png`
                : '/images/no-image.png'
            }
            alt={''}
            style={{
              maxHeight: '200px', // ปรับความสูงตามต้องการ
              maxWidth: '100%', // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          <p style={{ color: '#3F8CFF' }}>view</p>
        </div>
      ),
    },
    {
      // flex: 1,
      field: "action",
      headerName: 'จัดการ',
      width: 160,
      headerAlign: "center",
      align: "center",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <div style={{display:'flex',gap:'20px'}}>
         <ButtonUI
                onClick={() => handleOpenEditDate(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
              >
                แก้ไข
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row.id)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
              >
                ลบ
              </ButtonUI>
        </div>
      ),
    },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     Zone: "A",
  //     BlockNumber: "A01",
  //     BlockSize: "2.5x3m",
  //     thumbnail_link: "/images/products/Rectangle 532.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop A",
  //     Business: "อาหาร",
  //     RentalDate: "2020/01/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: " ",
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: " ",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "ตุลยากร ภัชรภิรมย์",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 2,
  //     Zone: "A",
  //     BlockNumber: "A02",
  //     BlockSize: "3x3m",
  //     thumbnail_link: "/images/products/Rectangle 532.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop B",
  //     Business: "เครื่องดื่ม",
  //     RentalDate: "2020/02/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "ชนัย คมมาก",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 3,
  //     Zone: "A",
  //     BlockNumber: "A03",
  //     BlockSize: "2x2m",
  //     thumbnail_link: "/images/products/Rectangle 532.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop C",
  //     Business: "เสื้อผ้าแฟร์ชั่น",
  //     RentalDate: "2020/03/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "โชคชัย พาใจขวัญ",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 4,
  //     Zone: "A",
  //     BlockNumber: "A04",
  //     BlockSize: "2x2.5m",
  //     thumbnail_link: "/images/products/Rectangle 532.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop D",
  //     Business: "เครื่องดื่ม",
  //     RentalDate: "2020/04/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "กุลนิดา ภูภักดี",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 5,
  //     Zone: "A",
  //     BlockNumber: "A05",
  //     BlockSize: "3x3.5m",
  //     thumbnail_link: "/images/products/Rectangle 532.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop E",
  //     Business: "เสื้อผ้าแฟร์ชั่น",
  //     RentalDate: "2020/05/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นันทิญา แสงสว่าง",

  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 6,
  //     Zone: "B",
  //     BlockNumber: "A06",
  //     BlockSize: "3x3.5m",
  //     thumbnail_link: "/images/products/Rectangle 533.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop F",
  //     Business: "อุปกรณ์",
  //     RentalDate: "2020/06/08",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นที กลับเพชร",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 7,
  //     Zone: "B",
  //     BlockNumber: "A07",
  //     BlockSize: "2.5x2.5m",
  //     thumbnail_link: "/images/products/Rectangle 526.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop G",
  //     Business: "อาหาร",
  //     RentalDate: "2020/07/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "จอมขวัญ แสงดารา",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 8,
  //     Zone: "G",
  //     BlockNumber: "A08",
  //     BlockSize: "2x2.5m",
  //     thumbnail_link: "/images/products/Rectangle 528.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop H",
  //     Business: "เสื้อผ้าแฟร์ชั่น",
  //     RentalDate: "2020/08/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "อัมพิกา พัฒน์ธนโกศล",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 9,
  //     Zone: "G",
  //     BlockNumber: "A09",
  //     BlockSize: "3x3m",
  //     thumbnail_link: "/images/products/Rectangle 529.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop I",
  //     Business: "เครื่องดื่ม",
  //     RentalDate: "2020/09/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "จันทรา กิตติเตชะคุณ",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 10,
  //     Zone: "E",
  //     BlockNumber: "A10",
  //     BlockSize: "2.5x3.5m",
  //     thumbnail_link: "/images/products/Rectangle 530.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop J",
  //     Business: "อุปกรณ์",
  //     RentalDate: "2020/10/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นนท์ มั่นคงประสิทธิ์",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 11,
  //     Zone: "E",
  //     BlockNumber: "A11",
  //     BlockSize: "2x2m",
  //     thumbnail_link: "/images/products/Rectangle 526.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop K",
  //     Business: "อาหาร",
  //     RentalDate: "2020/11/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นนท์ มั่นคงประสิทธิ์",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 12,
  //     Zone: "F",
  //     BlockNumber: "A12",
  //     BlockSize: "3x3.5m",
  //     thumbnail_link: "/images/products/Rectangle 533.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop L",
  //     Business: "เสื้อผ้าแฟร์ชั่น",
  //     RentalDate: "2020/12/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นนท์ มั่นคงประสิทธิ์",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 13,
  //     Zone: "G",
  //     BlockNumber: "A13",
  //     BlockSize: "2.5x2.5m",
  //     thumbnail_link: "/images/products/Rectangle 526.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop M",
  //     Business: "เครื่องดื่ม",
  //     RentalDate: "2021/01/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นนท์ มั่นคงประสิทธิ์",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 14,
  //     Zone: "G",
  //     BlockNumber: "A14",
  //     BlockSize: "3x3m",
  //     thumbnail_link: "/images/products/Rectangle 528.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop N",
  //     Business: "อุปกรณ์",
  //     RentalDate: "2021/02/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นนท์ มั่นคงประสิทธิ์",
  //     TentDeposit: 2000,
  //   },
  //   {
  //     id: 15,
  //     Zone: "F",
  //     BlockNumber: "A15",
  //     BlockSize: "2x2.5m",
  //     thumbnail_link: "/images/products/Rectangle 532.png",
  //     img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
  //     NameShop: "Shop O",
  //     Business: "เสื้อผ้าแฟร์ชั่น",
  //     RentalDate: "2021/03/01",
  //     Total: 6000,
  //     Deposit: 15000,
  //     Rent: 6500,
  //     WaterBill: 300,
  //     ElectricityBill: 2400,
  //     RentalStatus: "ยังเช่าอยู่",
  //     Fine: 200,
  //     StartDate: "2023/12/06",
  //     MoveOut: "2014/04/01",
  //     Date: "2024/04/28",
  //     NumberOfDays: "30 วัน",
  //     PayRent: "วันที่ 01 ของทุกเดือน",
  //     Name: "นนท์ มั่นคงประสิทธิ์",
  //     TentDeposit: 2000,
  //   },
  // ];

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={detailPayment}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: '90vw',
              maxHeight: '90vh',
              // height: "350px",
              width: '500px',
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default TablePayrent;
