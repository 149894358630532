import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { Typography, InputLabel } from "@mui/material";
import rows from "./data/TableData";
import { getPayRentData } from "../../services/payrent.service";

const PayRent = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [payRentData, setPayRentData] = useState([]);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [refreshData, setRefreshData] = useState(0);

  // console.log("payRentData", payRentData);

  useEffect(() => {
    getPayRentData().then((res) => {
      console.log(res.data);
      
      if (res.status === 200) {
        setPayRentData(res.data.rent);
      }
    });
  }, [refreshData]);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 130,
      sortable: true,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    // {
    //   field: "ImgShop",
    //   headerName: "ภาพหน้าร้าน",
    //   width: 120,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <img
    //       src={uploadPath + params.value}
    //       alt="Shop"
    //       style={{
    //         width: 100,
    //         borderRadius: "0.375rem",
    //         width: "59px",
    //         height: "59px",
    //       }}
    //       onClick={() => handleOpen(params.value)}
    //     />
    //   ),
    // },
    {
      field: "Name",
      headerName: "ชื่อผู้เช่าล่าสุด",
      sortable: false,
      width: 170,
      renderCell: (params) => (
        <div style={{ paddingLeft: "0" }}>
          <p>{params.row.tenant.name}</p>
        </div>
      ),
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 170,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "RentalDate",
      headerName: "วันที่เริ่มเช่า",
      width: 160,
      sortable: false,
    },
    {
      field: "TentDeposit",
      headerName: "ค่ามัดจำเต็นท์",
      width: 160,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Rent",
      headerName: "ค่าเช่า",
      width: 160,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },

    {
      field: "Deposit",
      headerName: "ค่ามัดจำ",
      width: 160,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "NumberRentalDays",
      headerName: "ระยะเวลาที่เช่าอยู่",
      width: 160,
    },
    {
      field: "RentalStatus",
      headerName: "สถานะการเช่า",
      width: 160,
      sortable: true,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.RentalStatus == 1 ? "#00D715" : "#FF2C2C",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {params.row.RentalStatus == 1 ? "ยังเช่าอยู่" : "ย้ายออกแล้ว"}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            { params.row.RentalStatus == 1 ? params.row.Endrent : params.row.MoveOutDate}
          </p>
        </div>
      ),
    },

    {
      field: "Update",
      headerName: "รายละเอียด",
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3" }}
          onClick={() => {
            const newWindow = window.open(
              "/payrent/details/" + params.row.boxId,
              "_blank"
            );
            if (newWindow) {
              newWindow.opener = null;
            }
          }}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
  ];

  const rowsFiltered = payRentData.filter((row) => {
    if (
      (age === "" || row.zone_name === age) && // Filter by Zone
      (row.BlockNumber.includes(searchInput) ||
        row.tenant.name.includes(searchInput))
    ) {
      return true;
    } else {
      return false;
    }
  });

  const zones = Array.from(new Set(payRentData.map((row) => row.zone_name)));

  const zoneOptions = zones
    .sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .map((zone, index) => (
      <MenuItem key={index} value={zone}>
        {zone}
      </MenuItem>
    ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header" style={{ marginBottom: "1.5rem" }}>
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          ประวัติ เช่า/ชำระค่าเช่า
        </div>
        <div className="search">
          <div className="">
            <FormControl fullWidth size="small" style={{ width: "100px" }}>
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={rentalStatus2}
                label="โซน"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="ค้นหา บล็อก,ชื่อผู้เช่า"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
            }}
            component="a"
            href="/products/add"
            className="button-h"
          >
            เพิ่มข้อมูลการเช่า
          </Button>
        </div>
      </div>
      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PayRent;
