const appConfigDEV = {
  isDevMode: true,
  language: 'th',
  timeZone: 'Europe/Paris',
  languageAvailable: ['th', 'en'],

  // apiPath:"http://192.168.1.53:8000/api/backoffice/v1/",
  // uploadPath:"http://192.168.1.53:8000/",
  // webPath: "http://192.168.1.53:3000/",

  apiPath: 'http://127.0.0.1:8000/api/backoffice/v1/',
  uploadPath: 'http://localhost:8000/',
  webPath: 'http://localhost:3000/',

  pages: {
    dashboard: true,
    messages: false,
    inbox: false,
    subscribe: false,
    productcate: true,
    products: true,
    tenant: true,
    members: false,
    slides: true,
    menu: false,
    category: true,
    posts: true,
    reports: false,
    webinfo: false,
    languages: false,
    admins: true,
    configs: false,
    profile: false,
    suppliers: true,
    amount: true,
    vat: true,
    groups: {
      notify: true,
      article: true,
      product: true,
      report: false,
      system: true,
    },
  },
  features: {
    multilingual: true,
    flexibleCategory: true,
    multipleImage: true,
    seo: true,
    price: false,
    tag: true,
    iframe: true,
    redirect: true,
    social: false,
    notify: false,
  },
};

const appConfigPROD = {
  isDevMode: false,
  language: 'th',
  timeZone: 'Asia/Bangkok',
  languageAvailable: ['th', 'en'],

  apiPath: 'https://api.ufo-market.com/api/backoffice/v1/',
  uploadPath: 'https://api.ufo-market.com/',
  webPath: 'https://api.ufo-market.com/',

  pages: {
    dashboard: true,
    messages: false,
    inbox: false,
    subscribe: false,
    productcate: true,
    products: true,
    tenant: true,
    members: false,
    slides: true,
    menu: false,
    category: true,
    posts: true,
    reports: false,
    webinfo: false,
    languages: false,
    admins: true,
    configs: false,
    profile: false,
    suppliers: true,
    amount: true,
    vat: true,
    groups: {
      notify: true,
      article: true,
      product: true,
      report: false,
      system: true,
    },
  },
  features: {
    multilingual: true,
    flexibleCategory: true,
    multipleImage: true,
    seo: true,
    price: false,
    tag: true,
    iframe: true,
    redirect: true,
    social: false,
    notify: false,
  },
};

// export default appConfigDEV;
export default appConfigPROD;
