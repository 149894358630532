import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Button,
  Modal,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import DataTable from './DataTable';
import ExpirationUpdate from './ExpirationUpdate/ExpirationUpdate';
import { exGetRentData } from '../../services/expiration.service';
import './ExpirationPage.scss';

const useRentData = (page, refreshData, age, searchQuery) => {
  const [rentData, setRentData] = useState([]);
  const [totals, setTotals] = useState({
    sumDeposit: 0,
    sumRent: 0,
    sumExpOverdue: 0,
  });
  const [paginate, setPaginate] = useState([]);
  const [zoning, setZoning] = useState([]);

  useEffect(() => {
    const fetchRentData = async () => {
      try {
        const res = await exGetRentData();
        if (res.status === 200) {
          setTotals({
            sumDeposit: res.data.sumDeposit,
            sumRent: res.data.sumRent,
            sumExpOverdue: res.data.sumExpOverdue,
          });
          setZoning(res.data.zones);
        }
      } catch (error) {
        console.error('Error fetching rent data:', error);
      }
    };

    const fetchData = async () => {
      try {
        // const urlzone = `expiration/rent/data?zone=${age}`;
        // axios.get(url);
        const url = `expiration/rent/data?page=${page + 1}&zone=${age}&search=${searchQuery}`;
        const response = await axios.get(url);
        setPaginate(response.data.rent);
        const resData = response.data.rent.data;
        const updatedData = resData.map((row) => ({
          ...row,
          total: row.TentDeposit + row.Rent + row.ElectricityBill,
        }));
        setRentData(updatedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchRentData();
    fetchData();
  }, [page, refreshData, age,searchQuery]);

  return { rentData, totals, paginate, zoning };
};

const ExpirationPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [age, setAge] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedShop, setSelectedShop] = useState(null);
  const [openshop, setOpenShop] = useState(false);
  const [openshopE, setOpenShopE] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [page, setPage] = useState(0);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  const { rentData, totals, paginate, zoning } = useRentData(page, refreshData,age,searchQuery);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleSearchInputChange = (e) => {
    console.log(e.target.value);
    
    setSearchQuery(e.target.value);
  };

  const filterDuplicatesInModal = useCallback((data) => {
    const uniqueNames = new Set();
    return data.filter((item) => {
      if (uniqueNames.has(item.tenantName)) {
        return false;
      }
      uniqueNames.add(item.tenantName);
      return true;
    });
  }, []);

  const handleResultClick = (selectedNameShop) => {
    const shop = rentData.find((row) => row.tenantName === selectedNameShop);
    setSelectedShop(shop);
    setSearchQuery(selectedNameShop);
    setOpenShop(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleOpenShopE = () => {
    if (Object.keys(selectedRows).length > 0) {
      setOpenShopE(true);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'โปรดเลือกข้อมูลที่ท่านต้องการก่อน',
      });
    }
  };

  const handleCloseShopE = () => {
    setOpenShopE(false);
  };

  const handleChange = (event) => {
    
    setAge(event.target.value);
  };

  const rowsFiltered = useMemo(() => {
    console.log(rentData);
    
    return rentData.filter((row) => {
      return (
        (age === "" || row.zoneId === age) &&
        row.tenantName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [rentData, age, searchQuery]);

  const zones = useMemo(() => {
    return Array.from(new Set(zoning.map((row) => row)));
  }, [rentData, age, searchQuery]);

  const zoneOptions = zones.sort().map((zone, index) => (
    <MenuItem key={index} value={zone.zoneId}>
      {zone.zone_name}
    </MenuItem>
  ));

  const formattedTotalDeposit = totals.sumDeposit.toLocaleString();
  const formattedTotalRent = totals.sumRent.toLocaleString();
  const formattedTotalElectricityBill = totals.sumExpOverdue.toLocaleString();

  const dataTotal = [
    { p: 'รวมค่าเช่า : ', price: formattedTotalRent },
    { p: 'รวมคงค้าง : ', price: formattedTotalElectricityBill },
    { p: 'รวมค่าปรับ : ', price: formattedTotalDeposit },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-Data">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));
  // const keySelected = Object.keys(selectedRows).slice(0, -1); // ตัด key สุดท้ายออก
  console.log(selectedRows);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className="header">
        <div style={{ fontSize: '1.75rem', fontWeight: 500 }}>
          วันที่ จ่าย/เก็บ ค่าเช่า
        </div>
        <div className="searchE">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">โซน</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="โซน"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>ทั้งหมด</em>
              </MenuItem>
              {zoneOptions}
            </Select>
          </FormControl>
          <Button
            className="button-h"
            variant="contained"
            size="small"
            style={{
              fontSize: '16px',
              background: '#6C5DD3',
              color: 'white',
              height: '39px',
              paddingLeft: '1.5rem',
              paddingRight: '1.5rem',
            }}
            onClick={handleOpenShop}
          >
            ค้นหาผู้เช่า
          </Button>
          <Modal open={openshop} onClose={handleCloseShop}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'white',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                p: 4,
              }}
            >
              <TextField
                label="ค้นหาผู้เช่า"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchInputChange}
                autoFocus
                sx={{ mb: 2 }}
                InputProps={{
                  style: {
                    color: 'black',
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'black',
                  },
                }}
              />
              <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: '1px solid #ddd',
                          padding: '8px',
                          textAlign: 'center',
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6C5DD3',
                        }}
                      >
                        เลือกผู้เช่า
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterDuplicatesInModal(rowsFiltered).map((result) => (
                      <tr
                        key={result.id}
                        onClick={() => handleResultClick(result.tenantName)}
                        style={{
                          cursor: 'pointer',
                          '&:hover': { backgroundColor: '#f0f0f0' },
                        }}
                      >
                        <td
                          style={{
                            borderBottom: '1px solid #ddd',
                            padding: '8px',
                          }}
                        >
                          {result.tenantName}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Modal>
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: '16px',
              background: '#707070',
              color: 'white',
              height: '39px',
            }}
            component="a"
            onClick={handleOpenShopE}
            className="button-h"
          >
            อัปเดตข้อมูล
          </Button>
        </div>
      </div>
      <Modal open={openshopE} onClose={handleCloseShopE}>
        <Box
          className="width-modalE"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            p: 4,
          }}
        >
          <ExpirationUpdate
            onClose={handleCloseShopE}
            totalSelectedDeposit={totals.sumDeposit}
            rents={totals.sumRent}
            overdus={selectedRows.map((row) => row.overdue)}
            idShop={selectedRows}
            blockAndRent={selectedRows.map((row) => row.rent)}
            blockNumbers={selectedRows.map((row) => row.blockNumber)}
            nameRent={selectedRows.map((row) => row.name)}
            nameShop={selectedRows.map((row) => row.NameShop)}
            setRefreshData={setRefreshData}
          />
        </Box>
      </Modal>
      <div className="data-box">{totalData}</div>
      <DataTable
        rowData={rowsFiltered}
        ImgPath={uploadPath}
        paginateData={paginate}
        page={page}
        setPage={setPage}
        setIdShop={setSelectedRows}
        setTotalSelectednameRent={(names) =>
          setSelectedRows((prev) => ({ ...prev, names }))
        }
        // setTotalSelectedNameShop={(shops) =>
        //   setSelectedRows((prev) => ({ ...prev, shops }))
        // }
        // setTotalSelectedBlockNumber={(blocks) =>
        //   setSelectedRows((prev) => ({ ...prev, blocks }))
        // }
        // setTotalSelectedRent={(rents) =>
        //   setSelectedRows((prev) => ({ ...prev, rents }))
        // }
        // srtTotalSelectedOverdue={(overdues) =>
        //   setSelectedRows((prev) => ({ ...prev, overdues }))
        // }
        setSelectedRows={setSelectedRows}
        // setTotalSelectedDeposit={(deposit) =>
        //   setSelectedRows((prev) => ({ ...prev, deposit }))
        // }
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: '90vw',
              maxHeight: '90vh',
              width: '500px',
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ExpirationPage;
