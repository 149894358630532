import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
} from '@mui/material';
// import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import './StoreRent.scss';
import { svUpdateTenantDetails } from '../../services/overalldisplay/tenantinfo.service';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Switch from '@mui/material/Switch';
import SwalUI from '../../components/ui/swal-ui/swal-ui';
// import { display } from '@mui/system';

function PayRenttDetails(props) {
  // const { t } = useTranslation(["dashboard-page"]);
  const { detailTenant, setRefreshData, setOpenDetail, selectBusiness } = props;
  const uploadPath = useSelector((state) => state.app.uploadPath);
  // const [loading, setLoading] = useState(true);
  // const [searchInput, setSearchInput] = useState('');
  // const [age, setAge] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchResults, setSearchResults] = useState([]);
  const [tenantData, setTenantData] = useState(detailTenant);
  // const [state, setState] = React.useState({
  //   gilad: true,
  //   jason: false,
  //   antoine: true,
  // });

  // const handleChange = (event) => {
  //   setTenantData({
  //     ...tenantData,display:event.target.checked,
  //   });
  // };

  // const handleSearchInputChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  // const [selectedShop, setSelectedShop] = useState('');

  const handleChangeBusiness = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    setTenantData((prevState) => ({
      ...prevState,
      shop: { ...prevState.shop, businessesId: selectedValue },
    }));
    // setAge(selectedValue);
  };

  const businessOptions = selectBusiness.map((zone, index) => (
    <MenuItem key={index} value={zone.id}>
      {/* {zone.types_name} */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            borderRadius: '3px',
            height: '15px',
            width: '15px',
            backgroundColor: zone.box_color,
            marginRight: '6px',
          }}
        />
        <div>{zone.types_name}</div>
      </div>
    </MenuItem>
  ));

  // const [openshop, setOpenShop] = useState(false);
  // const handleOpenShop = () => {
  //   setOpenShop(true);
  // };
  // const handleCloseShop = () => {
  //   setOpenShop(false);
  // };

  const formInputRef = useRef(null);
  // const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileUploader = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
        setTenantData((prevState) => ({
          ...prevState,
          shop: { ...prevState.shop, image: file },
        }));
      }
    };
    input.click();
  };

  const addValidators = () => {
    // console.log('123');
    const formData = new FormData();
    formData.append('shopId', tenantData.shopId);
    // formData.append('zoneName', tenantData.zone_name);
    // formData.append('zoneNumber', tenantData.zone_number);
    formData.append('nameShop', tenantData.shop.name);
    formData.append('businessesId', tenantData.shop.businessesId);
    formData.append('note', tenantData.shop.note);
    formData.append('Image', tenantData.shop.image || tenantData.shop.image);

    formData.append('tenantId', tenantData.tenantId);
    formData.append('nameTenant', tenantData.tenant.name);
    formData.append('nickname', tenantData.tenant.nickname);
    formData.append('phone', tenantData.tenant.phone);
    formData.append('line', tenantData.tenant.line);
    formData.append('telegram', tenantData.tenant.telegram);
    formData.append('whatsapp', tenantData.tenant.whatsapp);
    formData.append('name_contact', tenantData.tenant.name_contact);
    formData.append('phone_contact', tenantData.tenant.phone_contact);
    formData.append('mail', tenantData.tenant.mail);
    formData.append('rentboxesId', tenantData.boxRent.split(',').map(Number));
    formData.append('display', tenantData.display ? 1 : 0);

    /* Display the key/value pairs*/
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    // return false;
    svUpdateTenantDetails(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          setOpenDetail(false);
          SwalUI({
            status: res.data.status,
            description: res.data.description,
          });
          setRefreshData((prev) => prev + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section id="export-details-page">
      {/* {loading ? (
        <PulseLoader color="#3b326b" />
      ) : ( */}
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              gap: '2rem',
              marginBottom: '1rem',
            }}
          >
            <p style={{ width: '100%', fontSize: '1.75rem', fontWeight: 500 }}>
              อัปเดต ข้อมูลการเช่า
            </p>
          </div>
        </div>

        {/* box-shop */}
        <div className="box-shop1">
          <div className="herder-shop">
            <h1 style={{ fontSize: '1.5rem', fontWeight: '500' }}>
              ข้อมูลร้าน
            </h1>
          </div>
          <div className="from-shop-add">
            <div className="figure-container-add">
              <figure className="figure-add" style={{ cursor: 'pointer' }}>
                {selectedImage && (
                  <img
                    className="content-images-add"
                    src={selectedImage}
                    alt="Selected"
                  />
                )}

                {/* แสดงรูปภาพที่เลือก */}
                <div className="input-file">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={previewImageHandler}
                    ref={formInputRef}
                    style={{ display: 'none' }}
                  />
                </div>
                {selectedImage ? (
                  ''
                ) : (
                  // <div className="figure-text">
                  //   <InsertPhotoOutlinedIcon
                  //     fontSize="large"
                  //     style={{ color: 'grey' }}
                  //   />
                  //   <p
                  //     style={{
                  //       fontSize: '14px',
                  //       fontWeight: 400,
                  //       color: '#707070',
                  //       text: 'center',
                  //     }}
                  //   >
                  //     อัปโหลด รูปภาพหน้าร้าน
                  //   </p>
                  // </div>
                  <img
                    className="content-images-add"
                    src={uploadPath + detailTenant.shop.image}
                    alt="Selected"
                  />
                )}
              </figure>
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: '16px',
                  background: '#6C5DD3',
                  color: 'white',
                  height: '39px',
                  width: '108px',
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                }}
                className="button-h"
                onClick={openFileUploader}
              >
                อัปโหลด
              </Button>
            </div>

            <div className="text-from-shop">
              <TextField
                size="small"
                label="ชื่อร้าน"
                variant="outlined"
                value={detailTenant.shop ? tenantData.shop.name : ''}
                onChange={(event) =>
                  setTenantData((prevState) => ({
                    ...prevState,
                    shop: { ...prevState.shop, name: event.target.value },
                  }))
                }
              />
              <FormControl fullWidth size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-select-small-label">
                  ประเภทการทำธุรกิจ
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={tenantData.shop.businessesId}
                  label="ประเภทการทำธุรกิจ"
                  size="small"
                  onChange={handleChangeBusiness}
                >
                  {/* <MenuItem value={10}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        borderRadius: "3px",
                        height: "15px",
                        width: "15px",
                        backgroundColor: "#ff0088",
                        marginRight: "6px",
                        // border: "2px solid",
                      }}
                    />
                    <div>อาหาร</div>
                  </div>
                </MenuItem>
                <MenuItem value={20}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        borderRadius: "3px",
                        height: "15px",
                        width: "15px",
                        backgroundColor: "#00ff00",
                        marginRight: "6px",
                        // border: "2px solid",
                      }}
                    />
                    <div>เครื่องดื่ม</div>
                  </div>
                </MenuItem> */}
                  {/* เพิ่ม MenuItem เพิ่มเติมตามต้องการ */}
                  {businessOptions}
                </Select>
              </FormControl>
              <textarea
                aria-label="empty textarea"
                placeholder="รายระเอียดประเภทธุรกิจ"
                value={tenantData.shop ? tenantData.shop.note : ''}
                onChange={(event) =>
                  setTenantData((prevState) => ({
                    ...prevState,
                    shop: { ...prevState.shop, note: event.target.value },
                  }))
                }
                style={{
                  border: '1px solid #808080',
                  borderRadius: '5px',
                  padding: '8px',
                  width: '100%',
                  height: '80px',
                  resize: 'vertical',
                }}
              />
            </div>
          </div>

          {/* ข้อมูลผู้เช่า */}
          <div className="from-information">
            <h1 style={{ fontSize: '1.4rem', fontWeight: '500' }}>
              ข้อมูลผู้เช่า
            </h1>
            <div className="from-box-information">
              <div className="from-1">
                <TextField
                  size="small"
                  label="ชื่อ-นามสกุล"
                  variant="outlined"
                  value={tenantData.tenant ? tenantData.tenant.name : ''}
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: { ...prevState.tenant, name: event.target.value },
                    }))
                  }
                />
                <TextField
                  size="small"
                  label="เบอร์โทรศัพท์ (TH)"
                  variant="outlined"
                  value={tenantData.tenant ? tenantData.tenant.nickname : ''}
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: {
                        ...prevState.tenant,
                        nickname: event.target.value,
                      },
                    }))
                  }
                />
                <TextField
                  size="small"
                  label="เบอร์โทรศัพท์ (KH)"
                  variant="outlined"
                  value={tenantData.tenant ? tenantData.tenant.phone : ''}
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: {
                        ...prevState.tenant,
                        phone: event.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="from-2">
                <TextField
                  size="small"
                  label="ไอดี ไลน์"
                  variant="outlined"
                  value={tenantData.tenant ? tenantData.tenant.line : ''}
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: { ...prevState.tenant, line: event.target.value },
                    }))
                  }
                />
                <TextField
                  size="small"
                  label="Telegram"
                  variant="outlined"
                  value={tenantData.tenant ? tenantData.tenant.telegram : ''}
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: {
                        ...prevState.tenant,
                        telegram: event.target.value,
                      },
                    }))
                  }
                />
                <TextField
                  size="small"
                  label="WhatsApp"
                  variant="outlined"
                  value={tenantData.tenant ? tenantData.tenant.whatsapp : ''}
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: {
                        ...prevState.tenant,
                        whatsapp: event.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="from-3">
                <TextField
                  size="small"
                  label="ชื่อ-นามสกุล บุคคลที่ติดต่อได้"
                  variant="outlined"
                  value={
                    tenantData.tenant ? tenantData.tenant.name_contact : ''
                  }
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: {
                        ...prevState.tenant,
                        name_contact: event.target.value,
                      },
                    }))
                  }
                />
                <TextField
                  size="small"
                  label="เบอร์โทรศัพท์ บุคคลที่ติดต่อได้"
                  variant="outlined"
                  value={
                    tenantData.tenant ? tenantData.tenant.phone_contact : ''
                  }
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: {
                        ...prevState.tenant,
                        phone_contact: event.target.value,
                      },
                    }))
                  }
                />
                <TextField
                  size="small"
                  label="อีเมล"
                  variant="outlined"
                  value={tenantData.tenant ? tenantData.tenant.mail : ''}
                  onChange={(event) =>
                    setTenantData((prevState) => ({
                      ...prevState,
                      tenant: { ...prevState.tenant, mail: event.target.value },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="box-btn">
          {/* <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#FF2C2C",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            // onClick={handleReset}
          >
            ลบข้อมูล
          </Button> */}
          <FormControlLabel
            control={
              <Switch
                checked={tenantData.display ? true : false}
                onChange={(event) =>
                  setTenantData({
                    ...tenantData,
                    display: event.target.checked,
                  })
                }
                name="gilad"
                color="secondary"
              />
            }
            labelPlacement="start"
            label="ซ่อน"
          />

          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: '16px',
              background: '#707070',
              color: 'white',
              height: '39px',
              width: '100px',
            }}
            onClick={() => setOpenDetail(false)}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: '16px',
              background: '#6C5DD3',
              color: 'white',
              height: '39px',
              width: '100px',
            }}
            onClick={addValidators}
          >
            บันทึก
          </Button>
        </div>
      </>
      {/* )} */}
    </section>
  );
}

export default PayRenttDetails;
