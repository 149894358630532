import React, { useEffect, useState } from "react";
import { Modal, Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import "./FormAddmodal.scss";
import moment from "moment";
import { svElectricityBillUpdate } from "../../../services/expenses/electricitybill.service";

function FormEditmodal(props) {
  const { setRefreshData, openEdit, setOpenEdit, electricityBillEdit } = props;
  const [editData, setEditData] = useState(electricityBillEdit);
  const [isError, setIsError] = useState({
    uses_quantity: false,
    numbeunit_old: false,
    numbeunit_new: false,
    price_unit: false,
  });

  useEffect(() => {
    let p = editData.last_unit - editData.previous_unit;
    setEditData((prevState) => ({
      ...prevState,
      quantity_used: p,
    }));
  }, [editData.previous_unit, editData.last_unit]);

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      uses_quantity: editData.quantity < 1,
      numbeunit_old: editData.last_time < 1,
      numbeunit_new: editData.current < 1,
      price_unit: editData.expenses < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;
    if (isValid) {
      const formData = new FormData();
      formData.append("id_electricityBill", editData.id);
      formData.append(
        "date",
        typeof editData.date === "string"
          ? editData.date
          : moment(editData.date).format("DD/MM/YYYY")
      );
      formData.append("quantityUsed", editData.quantity_used);
      formData.append("previousNumber", editData.previous_unit);
      formData.append("lastNumber", editData.last_unit);
      formData.append("totalExpenses", editData.expenses);

      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;
      svElectricityBillUpdate(formData).then((res) => {
        console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            // //description: res.data.description
          });
          setOpenEdit(false);
        }
      });
    }
  };

  return (
    <Modal
      open={openEdit}
      onClose={() => {
        setOpenEdit(false);
      }}
    >
      <Box
        className="width-modal"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          p: 4,
        }}
      >
        <div className="box-modal-add">
          <div className="justifu-CancelIcon">
            <p
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              ข้อมูลค่าไฟ
            </p>
            <button
              onClick={() => {
                setOpenEdit(false);
              }}
            >
              <CancelIcon className="CancelIcon" />
            </button>
          </div>

          <div className="main-modal">
            <div className="box-modal-1">
              <div className="box-maintenance">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  วันที่เรียกเก็บ
                </p>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // label="ปี"
                    inputFormat="DD / MM / YYYY"
                    value={moment(editData.date, "DD / MM / YYYY")}
                    onChange={(momentdate) => {
                      setEditData((prevState) => {
                        return { ...prevState, date: momentdate };
                      });
                    }}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  เลขครั้งก่อน
                </p>
                <TextField
                  id="outlined-basic"
                  value={editData.previous_unit}
                  size="small"
                  // label="หัวข้อ"
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]", // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.numbeunit_old}
                  onChange={(e) =>
                    setEditData((prevState) => {
                      return { ...prevState, previous_unit: e.target.value };
                    })
                  }
                />
              </div>
              <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  ยอดค่าใช้จ่าย
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={editData.expenses}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]", // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                  error={isError.price_unit}
                  onChange={(e) =>
                    setEditData((prevState) => {
                      return { ...prevState, expenses: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="box-modal-2">
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  จำนวนที่ใช้
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={editData.quantity_used}
                  variant="outlined"
                  disabled
                  style={{ width: "100%", backgroundColor: "#f3f4f6" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  เลขครั้งหลัง
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={editData.last_unit}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]", // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                  error={isError.numbeunit_new}
                  onChange={(e) =>
                    setEditData((prevState) => {
                      return { ...prevState, last_unit: e.target.value };
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Button-save">
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "14px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
            className="button-h"
            onClick={editValidators}
          >
            บันทึก
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default FormEditmodal;
